import React, { PropsWithChildren, ReactNode, useEffect } from 'react'
import { createPortal } from 'react-dom'
import ReactTooltip, {
  Offset,
  Place,
  TooltipProps as ReactTooltipProps
} from 'react-tooltip'
import { twMerge } from 'tailwind-merge'

import useId from 'hooks/useId'

export interface TooltipProps extends ReactTooltipProps {
  tooltipBody: ReactNode
  className?: string
  contentWrapperClassname?: string
  contentWrapperStyle?: React.CSSProperties
  place?: Place
  disable?: boolean
  offset?: Offset
  html?: boolean | false
  showByDefault?: boolean
}

const Tooltip = ({
  children,
  tooltipBody,
  className,
  contentWrapperClassname,
  contentWrapperStyle,
  place = 'top',
  disable = false,
  offset = {},
  html,
  showByDefault = false,
  ...props
}: PropsWithChildren<TooltipProps>) => {
  const id = useId()
  const tooltipContainer = document.getElementById('tooltip-container')

  useEffect(() => {
    if (showByDefault) {
      const tooltip = document.querySelectorAll(`[data-tip][data-for="${id}"]`)[0]
      ReactTooltip.show(tooltip)
    }
  }, [id, showByDefault])

  if (!tooltipContainer) return null

  return (
    <>
      {createPortal(
        <ReactTooltip
          place={place}
          id={id}
          offset={offset}
          className={twMerge('!z-[1100] !p-2', className)}
          backgroundColor="#4D4D4D"
          effect="solid"
          disable={disable}
          html={html}
          {...props}
        >
          {tooltipBody}
        </ReactTooltip>,
        tooltipContainer
      )}
      <div
        style={contentWrapperStyle}
        className={twMerge('inline-flex', contentWrapperClassname)}
        data-for={id}
        data-tip=""
      >
        {children}
      </div>
    </>
  )
}

export default Tooltip
