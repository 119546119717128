import {
  addDays,
  addWeeks,
  differenceInHours,
  differenceInMinutes,
  getDay
} from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import dayjs from 'dayjs'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import moment from 'moment-timezone'

// For a full list of formats for date-fns, see https://date-fns.org/v2.21.1/docs/format
// Note that we use the format function from date-fns-tz, which is a wrapper around date-fns,
// because it supports timezones.
export const FULL_DATE_FORMAT = 'MMMM d, yyyy'
export const FULL_DATE_ABBREV_MONTH_FORMAT = 'MMM d, yyyy'
export const FULL_DATE_AND_TIME_ABBREV_MONTH_FORMAT = 'MMM d, yyyy hh:mm:ss a'
export const FULL_DATE_AND_TIME_NO_SEC_ABBREV_MONTH_FORMAT = 'MMM d, yyyy hh:mm a'
export const ISO_DATE_FORMAT = 'yyyy-MM-dd'
export const FULL_MONTH_DATE_FORMAT = 'MMMM d'
export const MONTH_ABBREV_DATE_FORMAT = 'MMM d'
export const MONTH_ABBREV_FORMAT = 'MMM'
export const MONTH_FULL_FORMAT = 'MMMM'
export const MONTH_ABBREV_YEAR_FORMAT = 'MMM yyyy'
export const MONTH_FULL_YEAR_FORMAT = 'MMMM yyyy'
export const DAY_OF_MONTH_FORMAT = 'd'
export const TWO_DIGIT_DATE = 'dd'
export const HOUR_MIN_ONLY_FORMAT = 'h:mm'
export const HOUR_MIN_FORMAT = 'h:mm a'
export const HOUR_TIME_OF_DAY_FORMAT = 'ha'
export const DAY_MONTH_YEAR_FORMAT = 'EEE - MMMM d, Y'
export const DAY_MONTH_FORMAT = 'EEE, d MMMM - h:mm a'
export const DAY_MONTH_ABBREV_FORMAT = 'EEE, d MMM - h:mm a'
export const DAY_MONTH_ABBREV_NO_TIME_FORMAT = 'EEE, d MMM'
export const DAY_MONTH_DATE_YEAR_FORMAT = 'EEEE, MMMM d, yyyy'
export const HOUR_SPACE_MIN_TIMEZONE_FORMAT = 'h:mm a z'

// Date-fn day constants
export const SUNDAY = 0
export const MONDAY = 1
export const TUESDAY = 2
export const WEDNESDAY = 3
export const THURSDAY = 4
export const FRIDAY = 5
export const SATURDAY = 6

/**
 * @param {string} utcDateString Date string in UTC format.
 * @param {string} timezone Timezone name in this format: "America/New_York".
 * @param {string} formatString Date format for date-fns. For options, see https://date-fns.org/v2.21.1/docs/format
 * @returns {string} Formatted date in user timezone.
 */
export function formatInTimezone(
  utcDateString: string = new Date().toISOString(),
  timezone: string,
  formatString: string
): string {
  const date = utcToNewTimezone(utcDateString, timezone)
  return format(date, formatString)
}

/**
 * @param {string} utcDateString Date string in UTC format: '1985-01-09T23:23:00+00:00' or '1985-01-09T23:23:00Z'
 * NOTE: Make sure the date string indicates that it is in UTC,
 *       or else it will be converted from UTC to the browser's timezone (and could change the date).
 *
 * @returns {string} A nicely formatted date, like so: January 9, 1985
 */
export function getPrettyFullDate(dateStr: string): string {
  return format(new Date(dateStr), FULL_DATE_FORMAT)
}

/**
 * @param {string} utcDateString Date string in UTC format.
 * @param {string} timezone Optional: Timezone name in this format: "America/New_York".
 *  Defaults to user timezone from local storage, and finally the browser timezone.
 * @returns {object} A new date object adjusted to the timezone.
 */
export function utcToNewTimezone(utcDateString: string, timezone: string): Date {
  return timezone
    ? new Date(utcToZonedTime(utcDateString, timezone))
    : new Date(utcDateString)
}

/**
 * @param {string} timezone Timezone name in this format: "America/New_York".
 * @returns {boolean; ''} true if valid; empty string if not valid
 */
export function isValidTimeZone(tz: string): boolean | '' {
  return !Intl.DateTimeFormat(undefined, { timeZone: tz }) ? '' : true
}

/**
 * @param {string} timezone Timezone name in this format: "America/New_York".
 * @returns {string} Timezone string (America/Denver)
 */
export function getCurrentTimezone(timezone?: string): string {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // If user passed in a timezone, check if it's valid.
  return timezone && timezone !== 'null' && moment.tz(timezone)
    ? timezone
    : browserTimezone
}

/**
 * @param {string} timezone Timezone name in this format: "America/New_York".
 * @param {date} date Date of event or first date of a series
 *  Defaults to user timezone on the relevant date based on their profile's timezone, and finally the browser timezone.
 * @returns {string} Something like 'PDT' or empty if not found.
 */
export function getTimezoneAbbreviation(timezone: string, date: string): string {
  let validDate = date
  const tz = getCurrentTimezone(timezone)
  if (!date) {
    validDate = new Date().toISOString()
  }

  try {
    const formattedDate = moment(validDate).format('YYYY-MM-DD')
    let abbrev = isValidTimeZone(tz) ? moment.tz(formattedDate, tz).format('z') : 'UTC'

    // NOTE: Returns UTC if tz not recognized.
    if (abbrev === 'UTC') {
      // NOTE: throws exception if not valid timezone.
      Intl.DateTimeFormat(undefined, { timeZone: tz })
    }

    // NOTE: When there's a miss with the library, it returns the offset from GMT.
    if (!isNaN(parseFloat(abbrev))) {
      const offset = parseFloat(abbrev)
      if (offset === 0) {
        abbrev = 'GMT'
      } else if (offset > 0) {
        abbrev = `GMT +${offset}`
      } else {
        abbrev = `GMT ${offset}`
      }
    }

    return abbrev
  } catch {
    return ''
  }
}

/**
 * @param {string} startDate Date in UTC the event is going to start.
 * @param {string} endDate Date in UTC the event is going to end.
 * @param {string} dateToCheck Date to check if it falls between start and end.
 * @return {boolean} returns true if date is between start and end
 */
export function isBetweenDates(
  startDate: string,
  endDate: string,
  dateToCheck?: string
): boolean {
  const date = dateToCheck
    ? Date.parse(new Date(dateToCheck).toUTCString())
    : Date.parse(new Date().toUTCString())

  const start = Date.parse(startDate)
  const end = Date.parse(endDate)

  if (date > end) return false

  return date > start && date < end
}

/**
 * @param {string} dateToCheck Date as UTC string to check if it is now
 * @return {boolean} returns true if date is now
 */
export function isDateNow(dateToCheck: string): boolean {
  return new Date().toUTCString() === new Date(dateToCheck).toUTCString()
}

/**
 * @param {string} dateToCheck Date in UTC to check if it is today
 * @return {boolean} returns true if date is today
 */
export function isDateToday(dateToCheck: string): boolean {
  const date = new Date(dateToCheck)
  const today = new Date()

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

/**
 * @param {string} dateToCheck Date to check in UTC
 * @param {number} numDays number of days from today (X)
 * @return {boolean} returns true if date is X days from today
 */
export function isDateXorLessDaysAfterToday(
  dateToCheck: string,
  numDays: number
): boolean {
  const date = new Date(dateToCheck)

  const xDaysAfterToday = new Date()
  xDaysAfterToday.setDate(xDaysAfterToday.getDate() + numDays)

  const today = new Date()

  if (date < today) {
    return false
  }

  return (
    date.getDate() <= xDaysAfterToday.getDate() &&
    date.getMonth() <= xDaysAfterToday.getMonth() &&
    date.getFullYear() <= xDaysAfterToday.getFullYear()
  )
}

/**
 * @param {string} endDate Date in UTC you want to check against.
 * @param {string} dateToCheck Date to check if it falls before end.
 * @return {boolean} returns true if dateToCheck is before endDate
 */
export function isBeforeDate(endDate: string, dateToCheck: string): boolean {
  const date = dateToCheck
    ? Date.parse(new Date(dateToCheck).toUTCString())
    : Date.parse(new Date().toUTCString())

  const end = Date.parse(endDate)

  return date < end
}

/**
 * @param {string} startDate Date in UTC you want to check against.
 * @param {string} dateToCheck Date to check if it falls after start.
 * @return {boolean} returns true if dateToCheck is after startDate
 */
export function isAfterDate(startDate: string, dateToCheck?: string): boolean {
  const date = dateToCheck
    ? Date.parse(new Date(dateToCheck).toUTCString())
    : Date.parse(new Date().toUTCString())

  const start = Date.parse(startDate)

  return date > start
}

/**
 * @param {string} startDate Date in UTC of one date you want to check.
 * @param {string} endDate Date in UTC of the second date you want to check.
 * @return {number} returns the number of days between the two dates
 */
export function numDaysBetweenDates(startDate: string, endDate: string): number {
  const end = endDate
    ? Date.parse(new Date(endDate).toUTCString())
    : Date.parse(new Date().toUTCString())

  const start = Date.parse(startDate)

  return Math.abs(Math.round((end - start) / (1000 * 60 * 60 * 24)))
}

/**
 * @param {Date} datetime Date object already in the correct timezone for the user.
 * @return {string} The day of the week.
 */
export function getDayOfWeek(datetime: Date): string {
  return new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(datetime)
}

export function getDurationInMinutes(startsAtUtc: string, endsAtUtc: string): number {
  return differenceInMinutes(new Date(endsAtUtc), new Date(startsAtUtc))
}

export function getDurationInHours(startsAtUtc: string, endsAtUtc: string): number {
  return differenceInHours(new Date(endsAtUtc), new Date(startsAtUtc))
}

export function getFormattedStartDate(
  startsAtUtc: string,
  timeZone: string,
  format: string = DAY_MONTH_YEAR_FORMAT
): string {
  return formatInTimezone(startsAtUtc, timeZone, format)
}

export function getFormattedTimeWithTimeZone(
  startsAtUtc: string,
  endsAtUtc: string,
  timezone: string
): string {
  const abbreviatedTimezone = getAbbreviatedTimezone(startsAtUtc, timezone)

  const formattedTimeWithTimezone = `${getFormattedTime(
    startsAtUtc,
    timezone
  )} - ${getFormattedTime(endsAtUtc, timezone)} ${abbreviatedTimezone}`

  return formattedTimeWithTimezone
}

export function getFormattedTime(time: string, timezone: string) {
  return formatInTimezone(time, timezone, HOUR_MIN_FORMAT)
}

export function getAbbreviatedTimezone(dateUtc: string, timezone: string): string {
  const abbreviatedTimezone = getTimezoneAbbreviation(timezone, dateUtc)
  return abbreviatedTimezone ? `(${abbreviatedTimezone})` : ''
}

/**
 * @param {string} utcDateString UTC Date string to add weeks to.
 * @param {number} numWeeks The amount of weeks to add.
 * @return {Date} Resulting date from adding numWeeks weeks
 */
export function addWeeksToUtc(
  utcDateString: string = new Date().toISOString(),
  numWeeks: number
): Date {
  return addWeeks(new Date(utcDateString), numWeeks)
}

/**
 * @param {string} utcDateString the UTC date string to calculate the day offset from
 * @param {number} dayOfWeek the date-fns day index (0 thru 6/Sunday-Saturday) to find
 * @return {Date} The nearest day of dayOfWeek type to utcDateString
 */
export function findNearestDay(date: Date, dayOfWeek: number): Date {
  const daysToAdd = dayOfWeek - (getDay(date) % 6)
  return addDays(date, daysToAdd)
}

TimeAgo.addDefaultLocale(en)

const timeAgoClient = new TimeAgo('en-US')

/**
 * @param {string} utcDateString the UTC date string to calculate time ago string from
 * @return {string} The offset from current date in readable text
 */
export function timeAgo(date: Date) {
  return timeAgoClient.format(date)
}

export const convertUTCToLocalDate = (date?: Date): Date | null => {
  if (!date) {
    return null
  }
  date = new Date(date)
  date = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  )
  return date
}

export const convertLocalToUTCDate = (date?: Date): Date | null => {
  if (!date) {
    return null
  }
  date = new Date(date)
  date = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  )
  return date
}

export const formatDateRange = (startsAt?: string | null, endsAt?: string | null) => {
  if (!startsAt || !endsAt) return ''

  const startDate = new Date(startsAt)
  const endDate = new Date(endsAt)

  const startMonth = format(startDate, MONTH_FULL_FORMAT)
  const endMonth = format(endDate, MONTH_FULL_FORMAT)

  const startDay = startDate.getDate()
  const endDay = endDate.getDate()

  if (startMonth === endMonth) {
    return `${startMonth} ${startDay}-${endDay}`
  } else {
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}`
  }
}

export const formatTimeAsMinutesSeconds = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().substring(14, 19)
}

export const formatTimeAsHoursMinutes = (minsParam: number) => {
  if (!minsParam) {
    return null
  }

  const roundedMins = Math.round(minsParam)

  if (roundedMins === 0) {
    return '1 min'
  }

  const hours = Math.floor(roundedMins / 60)
  const mins = roundedMins % 60

  if (hours > 0) {
    return mins ? `${hours} hr, ${mins} min` : `${hours} hr`
  } else {
    return `${mins} min`
  }
}

export const withinDays = (dateToCheck: string, numDays = 14) => {
  if (dateToCheck === undefined) return false

  const dateLimit = new Date()
  dateLimit.setDate(dateLimit.getDate() - numDays)
  if (new Date(dateToCheck) > dateLimit) {
    return true
  }
  return false
}

/**
 * Formats a date as the season it belongs to. Doesn't account for southern hemisphere users.
 * Returns null if invalid date is passed in.
 * ex: 2023-10-01 => Fall 2023
 * @param {string | Date} date - Date to format
 */
export const formatSeason = (date: string | Date): string | null => {
  const formattedDate = dayjs(date)

  if (!formattedDate.isValid()) {
    return null
  }

  const month = formattedDate.month() + 1

  let season = ''
  if (month >= 3 && month <= 5) {
    season = 'Spring'
  } else if (month >= 6 && month <= 8) {
    season = 'Summer'
  } else if (month >= 9 && month <= 11) {
    season = 'Fall'
  } else {
    season = 'Winter'
  }

  const year = formattedDate.year()

  return `${season} ${year}`
}

export const getTimeOfDay = () => {
  const now = new Date()
  const hour = now.getHours()
  if (hour < 12) {
    return 'morning'
  } else if (hour < 18) {
    return 'afternoon'
  } else {
    return 'evening'
  }
}

export const safeFormatInTimezone = (
  datetimeStringUtc: string,
  timezone: string,
  format: string
): {
  error: Error | null
  result: string | null
} => {
  try {
    return {
      result: formatInTimezone(datetimeStringUtc, timezone, format),
      error: null
    }
  } catch (e) {
    return { result: null, error: e as Error }
  }
}

// Returns the number of milliseconds in a given number of days
export const daysInMs = (days: number) => days * 24 * 60 * 60 * 1000
